<template>
    <!-- 예약팝업 -->
    <div v-show="pp">
        <div class="basic_pp top_down" :class="{'off':off}">
            <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
            <div class="content bg-body overflow-hidden bg-opacity-80 backdrop-blur-30 px-0">
                <div class="header position-sticky flex-between-center pb-3 text-truncate px-3">
                    <h5>문의 정보</h5>
                    <i class="p-2 fal fa-times" @click="pp_off()"></i>
                </div>
                <div class="body overflow-auto pt-1" ref="basic_pp_body">
                    <ul class="last-border-0">
                        <li class="border-bottom border-gray-blur-pp py-1 mx-3" >
                            <div class="flex-between-center py-2">
                                <div class="icon_box position-relative flex-shrink-0 align-self-start">
                                    <div class="icon_bg w-px-42 h-px-42 rounded-4 shadow-bg shadow-bg-200" style="background-color: #f78178"></div>
                                    <div class="text-white fs-6 position-absolute top-50 start-50 translate-middle w-100 text-center lh-1">
                                        <i class="fas fa-home-alt"></i>
                                    </div>
                                </div>
                                <div class="txt_box text-start px-3 flex-grow-1">
                                    <div class="fw-500 lh-sm">홈페이지</div>
                                    <div class="small text-gray-relative-600 lh-sm"><a href="https://golf-yacht.com">https://golf-yacht.com</a></div>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom border-gray-blur-pp py-1 mx-3" >
                            <div class="flex-between-center py-2">
                                <div class="icon_box position-relative flex-shrink-0 align-self-start">
                                    <div class="icon_bg w-px-42 h-px-42 rounded-4 shadow-bg shadow-bg-200" style="background-color: #60c985"></div>
                                    <div class="text-white fs-6 position-absolute top-50 start-50 translate-middle w-100 text-center lh-1">
                                        <i class="fas fa-envelope"></i>
                                    </div>
                                </div>
                                <div class="txt_box text-start px-3 flex-grow-1">
                                    <div class="fw-500 lh-sm">문의 메일</div>
                                    <div class="small text-gray-relative-600 lh-sm"><a href="mailto:golf-yacht@golf-yacht.com">golf-yacht@golf-yacht.com</a></div>
                                </div>
                            </div>
                        </li>

                        <li class="border-bottom border-gray-blur-pp py-1 mx-3" >
                            <div class="flex-between-center py-2">
                                <div class="icon_box position-relative flex-shrink-0 align-self-start">
                                    <div class="icon_bg w-px-42 h-px-42 rounded-4 shadow-bg shadow-bg-200" style="background-color: #90a7d1"></div>
                                    <div class="text-white fs-6 position-absolute top-50 start-50 translate-middle w-100 text-center lh-1">
                                        <i class="fas fa-phone-alt"></i>
                                    </div>
                                </div>
                                <div class="txt_box text-start px-3 flex-grow-1">
                                    <div class="fw-500 lh-sm">문의 연락처</div>
                                    <div class="small text-gray-relative-600 lh-sm"><a href="tel:1566-7125">1566-7125</a></div>
                                </div>
                            </div>
                        </li>

                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
export default {
    data(){
        return{
            list:[],
            // front ui
			pp: false,
			off: false,
            timeout_id: null,
            total : '',
            login : this.$store.state.login
        }
    },
    mounted(){
        if(this.login ==true){
            // this.GetPushList();
        }
        
    },
    methods:{
        CheckTypeStyle(type){
            if(type =='P'){
                return `background-color: #60c985`
            }else if(type =='C'){
                return `background-color: #b7b7b7`
            }else if(type =='RB'){
                return `background-color: #90a7d1`
            }else if(type =='N'){
                return `background-color: #e3d559`
            }
        },
        CheckTypeIcon(type){
            if(type =='P'){
                return `fas fa-calendar`
            }else if(type =='C'){
                return `fas fa-times`
            }else if(type =='RB'){
                return `fas fa-clock`
            }else if(type =='N'){
                return `fas fa-bell`
            }
        },
        GetPushList(){
            const body = {}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/front/common/GetPushList', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  

                            this.list = body.list;

                        }else if(res.data.code=='9999'){
                            // this.$store.dispatch('SETLOGOUT').then(
                            //     ()=>{
                            //         window.location.href="/signin";
                            //     }
                            // )
                        }
                    }
                }
            )
        },
        ClickPush(item){
            const link = item.link;
            const code = item.code;
            const body = {code}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/front/common/CheckNotice', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            this.pp_off();
                            window.location.href=link;
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
            
        },
        // front ui
        pp_off(){
            this.off = true;

            if (this.timeout_id) {
                clearTimeout(this.timeout_id);
            }

            this.timeout_id = setTimeout(() => {
                this.pp = false;
                this.off = false;
                if( this.$refs.basic_pp_body ){
                    this.$refs.basic_pp_body.scrollTop = 0;
                }
                this.timeout_id = null;
            }, 200);
        }
    }

}
</script>
<style lang="scss" scoped>
    .dark{
        .border-gray-blur-pp{ border-color: #555555 !important; }
        .icon_box .icon_bg{
            opacity: 0.7;
        }
    }
    .light{
        .border-gray-blur-pp{ border-color: #d7d7d7 !important; }
    }
</style>