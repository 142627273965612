<template></template>
  
<script>
export default {
    mounted(){
        const login = this.$store.state.login;

        if(!login){
            // this.$router.push({path: '/signin'})
        }
    }    
}
</script>
  
