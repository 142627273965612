<template>
  <Header class="p-3">
    <div class="flex-between-center">
        <div class="profile fs-5 cusor-pointer" @click="GotoMove('/mypage/profile')">
            <!-- <router-link to=> -->
                <span class="text-white">{{ name }}</span>
                <i class="text-light fas fa-chevron-right text-opacity-50 fa-xs ms-2"></i>
            <!-- </router-link> -->
        </div>
        <div class="menu">
            <button class="btn border-0 rounded-4 shadow p-1 ms-2 w-px-30 h-px-30 bg-white" @click="OpenPopupInfo()"  ><i class="fas fa-info p-1 text-success"></i></button>
            <button class="btn border-0 rounded-4 shadow p-1 ms-2 w-px-30 h-px-30 bg-white" @click="GotoBook()" ><i class="fas fa-bookmark p-1 text-success"></i></button>
            <button class="border-0 position-relative btn rounded-4 shadow p-1 ms-2 w-px-30 h-px-30 bg-white" @click="OpenPopup()"  >
                <i class="fas fa-bell p-1 text-success"></i>
                <span class="position-absolute badge rounded-pill bg-danger fs-px-10" v-if="total > 0">{{ total }}</span>
            </button>
            <!-- yacht 버튼 - 누르면 요트예약 앱으로 테마 변함, /yacht router children -->
            <!-- <img src="@/assets/img/yacht.png" alt="" class="shadow ms-2 w-px-42 rounded-circle"> -->
        </div>
    </div>
    <PopupInfo ref="PopupInfo" />
    <PushPopup ref="PushPopup" />
  </Header>
</template>

<script>
import PushPopup from '@/components/popup/Push.vue'
import PopupInfo from '@/components/popup/Info.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        PushPopup,PopupInfo
    },
    data(){
        return{
            name:'',
            login:this.$store.state.login,
            total :0,
        }
    },
    mounted(){
        if(this.login ==false){
            this.name=""
            // this.$router.push({path:'/signin'})
        }else{
            this.name = this.$store.state.name;
            this.ShopNotice()
        }
        
    },
    methods:{
        GotoMove(path){
            if(this.login ==true){
                this.$router.push({path})   
            }else{
                this.$router.push({path:'/signin'})
            }
        },
        OpenPopupInfo(){
            this.$refs.PopupInfo.pp = true   
        },
        OpenPopup(){
            if(this.login ==true){
                this.$refs.PushPopup.pp = true   
            }else{
                this.$router.push({path:'/signin'})
            }
        },
        GotoBook(){
            if(this.login ==true){
                this.$router.push({path : '/bookmarks'})
            }else{
                this.$router.push({path:'/signin'})
            }
        },
        ShopNotice(){
            const body = {}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/front/common/GetTotalPush', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  

                            this.total = body.info.total;

                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        },
    

    }
    

}
</script>

<style>
.cusor-pointer{
        cursor: pointer;
    }
</style>