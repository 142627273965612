<template>
    <div class="home">
        <Gradient></Gradient>
        <Header></Header>
        <VueSlickCarousel :arrows="false" v-bind="settings" class="main_banner" v-if="top_list.length > 0">
            <!-- <div class="card shadow rounded-4 ms-3 border-0" v-for="(item,index) in top_list" :key="index" :style="{'backgroundImage':'url('+item.image_path+')'}" style="height:200px;"> -->
            <div class="card shadow rounded-4 ms-3 border-0" v-for="(item,index) in top_list" :key="index" style="height:200px;">
                <img :src="item.image_path" class="w-100 position-absolute start-0 top-0 bg-gray-100" alt="...">
                <!-- <v-lazy-image :src="item.image_path" 
                :src-placeholder="require('@/assets/img/loading_2.png')"
                class="w-100 position-absolute start-0 top-0 bg-gray-100" alt="..." /> -->
                <div @click="OpenDetail(top_list, index)" class="position-relative txt_box">
                    <div class="card-body py-3 pt-4">
                        <a href="javascript:void(0)" class="position-absolute top-0 end-0 m-3">
                            <i class="text-light text-shadow fal fa-home"></i>
                        </a>
                        <!-- <button class="border-0 p-2 w-px-38 position-absolute bottom-0 end-0 translate-middle-y me-3 btn btn-sm bg-white bg-opacity-75 rounded-3" @click.prevent.stop="$refs.ReservationPopup.pp = true"><i class="fal fa-calendar-alt mb-1"></i></button> -->
                        <h6 class="card-title text-truncate text-white mb-6">{{item.name}}</h6>
                        <div class="text-white fs-px-14">
                            <div class="text-truncate">
                                <i class="fas fa-map-marker-alt w-px-20"></i>{{ item.address }}
                            </div>
                            <div>
                                <i class="fas fa-phone-alt w-px-20"></i>{{ item.tel }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </VueSlickCarousel>
        <ul class="category d-flex px-3 pt-2 pb-2 fs-5 text-gray-relative-500">
            <li class="me-4" @click="category=1" :class="{'activated text-body':category===1}">국내 골프장</li>
            <li class="me-4" @click="category=2" :class="{'activated text-body':category===2}">해외 골프장</li>
        </ul>
        <div class="category_list px-3">
            <div class="flex-between-center mb-3">
                <span class="text-gray-relative-600 fs-px-13">산들바람 맞으며 신나는 하루 되세요!</span>
                <router-link :to="category === 1 ? '/search/domestic' : '/search/oversea'" class="fs-px-13 fw-bold text-main">더보기</router-link>
            </div>
            <ul class="d-flex flex-wrap justify-content-between">
                <li class="category_item border mb-2 border-gray-relative-200" v-for="(item,index) in category === 1? domestic_list : oversea_list" :key="index">
                    <div @click="OpenDetail(category === 1? domestic_list : oversea_list, index)">
                        <div class="card bg-body border-0 position-relative">
                            <div class="ratio ratio-4x3">
                                <img :src="item.image_path" class="card-img-top" alt="..." style="height:100%;">
                                <!-- <v-lazy-image :src="item.image_path" 
                                :src-placeholder="require('@/assets/img/Loading_icon.gif')"
                                class="card-img-top bg-gray-300" alt="..." style="height:100%;" v-if="item.image_path !=''" /> -->
                                <div class="w-100 h-100 flex-shrink-0 bg-gray-relative-200 position-absolute"  v-if="item.image_path==''"><i class="fas fa-golf-club fa-2x position-absolute top-50 start-50 translate-middle text-gray-relative-400"></i></div>
                            </div>
                            <!-- <div class="btn btn-sm d-flex flex-column text-center btn-main bg-opacity-50 text-white p-2 rounded-3 w-px-42 h-px-42 shadow-300 m-2 position-absolute end-0 justify-content-center" v-if="category === 2"  @click.prevent.stop="$refs.ReservationPopup.pp = true"><i class="fal fa-calendar-alt"></i></div> -->
                            <div class="card-body">
                                <p class="card-text fs-px-14 fw-bold text-trunacte-2">{{ item.name }}</p>
                                <div class="fs-px-13 text-truncate w-100 text-gray-relative-600">{{ item.address }}</div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        
        </div>
        <SearchDetailPopup ref="SearchDetailPopup" />
        <ReservationPopup ref="ReservationPopup" />
        <LoginCheck/>
        
        <div v-for="(item,index) in popup_list" :key="index">
            
            <div class="dimmed" v-if="item.open" @click="CheckPopupClose(index)">
                <div class="popup-wrap w-100">
                     <div class="header position-sticky flex-between-center text-truncate p-2 bg-primary text-white" v-if="item.title !=''">
                        <h5 class="col-10 text-truncate">{{item.title}}</h5>
                        <i class="p-2 fal fa-times" @click="CheckPopupClose(index)"></i>
                    </div>
                    <div class="popup-body">
                        <div class="card rounded-0 border-0" style="max-height:500px;overflow:auto;min-height:300px" v-if="item.image !=''">
                            <img :src="item.image" class="w-100 position-absolute start-0 top-0 bg-gray-100" alt="...">
                        </div>
                        <div v-html="item.memo" alt="" v-if="item.memo !='' && item.image ==''" class="p-3"   style="height:300px;overflow-y:auto">
                            
                        </div>
                    </div>
                    <div class="popup-footer w-100 text-end p-3 text-muted small">
                        <a href="javascript:void(0)" @click="NoshowDate(index)">하루 동안 열지 않기</a>
                        <span class="mx-3">|</span>
                        <span class="cursor-pointer" @click="CheckPopupClose(index)">창닫기</span>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");

import Header from '@/components/common/Header.vue'
import Gradient from '@/components/common/Gradient.vue'
import LoginCheck from '@/components/common/LoginCheck.vue'
import VueSlickCarousel from 'vue-slick-carousel'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import SearchDetailPopup from '@/components/popup/SearchDetail.vue'
import ReservationPopup from '@/components/popup/Reservation.vue'

export default {
    name: 'Home',
    components: {
        Header,
        Gradient,
        VueSlickCarousel,
        ReservationPopup,
        SearchDetailPopup,
        LoginCheck,
    },
    data() {
        return {
            category: 1,
            settings: {
                "autoplay": true,
                "autoplaySpeed": 2500,
                "infinite": true,
                "speed": 1000,
                "slidesToScroll": 1,
                "swipeToSlide": true,
                "variableWidth": true,
            },
            login : this.$store.state.login,
            top_list: [],
            domestic_list: [],
            oversea_list: [],
            popup_list : [],
            pp_list: [
                {
                    img: require('@/assets/img/main_popup1.png'),
                    link: '/cs/notice/23eb872d-cdf3-40fd-9a36-60b6fda3c270'
                }
            ],
            pp: false,
        }
    },
    mounted(){
        
        this.GetPopup();
        if(this.login ==true){
            this.GetTopShop();
            this.GetOverseaList();
            this.GetDomesticList();
        }else{
            this.GetTopShopNon();
            this.GetOverseaListNon();
            this.GetDomesticListNon();
        }
        
    },
    methods: {
        CheckPopupClose(index){
            this.popup_list[index].open = false;
        },
        NoshowDate(index){
            const code = this.popup_list[index].code;
            this.$cookies.set("POPUP" + code,"N",86400);
        },
        OpenDetail(list, idx) {
            this.$refs.SearchDetailPopup.item = list[idx];
            this.$refs.SearchDetailPopup.pp = true;
        },
        GetTopShop(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/dashboard/GetTopList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.top_list = res.data.body.list;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );                
        },
        GetPopup(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/dashboard/GetPopup',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const popup = res.data.body.list;
                            
                            let popup_list = [];
                            for (const el of popup) {
                                const code = el.code;
                                const popup = this.$cookies.get("POPUP"+code);
                                if(popup !='N'){
                                    popup_list.push(el);
                                }
                            }
                            this.popup_list = popup_list;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );                
        },
        GetOverseaList(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            // this.loading = true;
            this.$http.post('/front/dashboard/GetOverseaList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            // const e_body = res.data.body;
                            // const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            // const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            // const body = JSON.parse(d_res)
                            this.oversea_list = res.data.body.list;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );                
        },
        GetDomesticList(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            // this.loading = true;
            this.$http.post('/front/dashboard/GetDomesticList',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            // const e_body = res.data.body;
                            // const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            // const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            // const body = JSON.parse(d_res)
                            this.domestic_list = res.data.body.list;;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );                
        },
        GetTopShopNon(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            // this.loading = true;
            this.$http.post('/front/dashboard/GetTopListNon',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            // const e_body = res.data.body;
                            // const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            // const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            // const body = JSON.parse(d_res)
                            this.top_list = res.data.body.list;;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );                
        },
        GetOverseaListNon(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            // this.loading = true;
            this.$http.post('/front/dashboard/GetOverseaListNon',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            // const e_body = res.data.body;
                            // const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            // const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            // const body = JSON.parse(d_res)
                            this.oversea_list = res.data.body.list;;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );                
        },
        GetDomesticListNon(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            // this.loading = true;
            this.$http.post('/front/dashboard/GetDomesticListNon',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            // const e_body = res.data.body;
                            // const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            // const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            // const body = JSON.parse(d_res)
                            this.domestic_list = res.data.body.list;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    this.$router.push({path:'/signin'});
                                }
                            )
                        }
                    }
                }
            );                
        }
    },
}
</script>
<style lang="scss" scoped>
    .gradient{
      position: absolute;
      top: -130px;
      left: 50%;
      transform: translateX(-50%);
      height: 370px;
      width: 160%;
      z-index: -1;
      border-radius: 50%;
      background: #11998e;  /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, #38ef7d, #11998e);  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    .home {
        // overflow: hidden;
        position: relative;
        -webkit-overflow-scrolling : touch;
    }

    .category {
        li.activated {
            color: #333;
            font-weight: bold;
        }
    }

    .category_list {
        .category_item {
            width: 49%;
            border-radius: .75rem;
            overflow: hidden;
            -webkit-overflow-scrolling : touch;
        }
    }
</style>
<style lang="scss">
    .main_banner {
        .slick-slide {
            width: 19rem;
            margin-right: 1rem;
        }

        .slick-list {
            padding-bottom: 20px;
        }
    }

    .main_banner {
        .card {
            position: relative;
            z-index: 1;
            overflow: hidden;
            -webkit-overflow-scrolling : touch;
            background-position: center center;
            background-size: cover;

            &::after {
                content: '';
                left: 0;
                top: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: rgba(0, 0, 0, 0.2);
                z-index: 1;
            }
            img{
                position: relative;
                object-fit: cover;
                object-position: center center;
                height: 200px;
                z-index: 0;
            }
            .txt_box{
                position: relative;
                z-index: 3;
            }

            // img:not(.v-lazy-image-loaded){
            //     z-index: 3;
            // }
            // img:not(.v-lazy-image-loaded)+.txt_box{
            //     z-index: 3;
            // }
        }
    }
    .card-img-top {
        object-fit: cover;
        object-position: center ;
    }
</style>