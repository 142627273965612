import AlertTemp from '@/components/other/AlertTemp.vue';

export default{
    install(Vue){ 
        let alert_list = [];
        
        function createAlertInstance(msg, is_confirm, okFn, cancelFn) {
            let idx = alert_list.length > 0 ? alert_list.length : 0;
            const AlertComponent = Vue.extend(AlertTemp);
            const AlertInstance = new AlertComponent({
                propsData: {
                    idx: idx,
                    msg: msg,
                    is_confirm: is_confirm
                },
                methods: {
                    ok() {
                        okFn && okFn();
                        alert_off(idx);
                    },
                    cancel() {
                        cancelFn && cancelFn();
                        alert_off(idx);
                    }
                }
            });
            alert_list.push(AlertInstance);
            AlertInstance.$mount();
            document.querySelector('#app').appendChild(AlertInstance.$el);
        }

        // function createPpInstance(msg, is_confirm, ok_btn_txt, cancel_btn_txt, okFn, cancelFn) {
        //     let idx = alert_list.length > 0 ? alert_list.length : 0;
        //     const AlertComponent = Vue.extend(AlertTemp);
        //     const AlertInstance = new AlertComponent({
        //         propsData: {
        //             idx: idx,
        //             msg: msg,
        //             is_confirm: is_confirm,
        //             ok_btn_txt: ok_btn_txt,
        //             cancel_btn_txt: cancel_btn_txt
        //         },
        //         methods: {
        //             ok() {
        //                 okFn && okFn();
        //                 alert_off(idx);
        //             },
        //             cancel() {
        //                 cancelFn && cancelFn();
        //                 alert_off(idx);
        //             }
        //         }
        //     });
        //     alert_list.push(AlertInstance);
        //     AlertInstance.$mount();
        //     document.querySelector('#app').appendChild(AlertInstance.$el);
        // }
        
        Vue.prototype.$alert = function(msg) {
            return new Promise((resolve, reject) => {
                createAlertInstance(msg, false, resolve);
            });
        };
          
        Vue.prototype.$confirm = function(msg) {
            return new Promise((resolve, reject) => {
                createAlertInstance(msg, true, () => resolve({is_ok: true}), () => resolve({is_cancel: true}));
            });
        };
        
        // Vue.prototype.$pp = function(obj) {    //버튼 글자 변경 가능
        //     return new Promise((resolve, reject) => {
        //         const msg = obj.msg;
        //         const is_confirm = obj.is_confirm;
        //         const ok_btn_txt = obj.ok_btn_txt;
        //         const cancel_btn_txt = obj.cancel_btn_txt;

        //         createCustomAlertInstance(msg, is_confirm, ok_btn_txt, cancel_btn_txt, () => resolve({is_ok: true}), () => resolve({is_cancel: true}));
        //     });
        // };
        
        function alert_off(idx){
            alert_list[idx].$destroy();
            alert_list[idx].$el.remove();
            alert_list.splice(idx, 1);
        }
    }
}
