<template>
  <div class="basic_pp bottom_up" v-if="pp" :class="{ off: off }">
    <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
    <div class="content bg-body">
      <div class="header position-sticky flex-between-center pb-3">
        <h5 class="text-truncate">{{ title }}</h5>
        <i class="fal fa-times p-2" @click="pp_off()"></i>
      </div>
      <div
        class="body overflow-auto fs-px-13"
        ref="basic_pp_body"
        v-html="desc"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '개인정보 처리방침 동의',
      desc: ``,

      plan_name: '',
      // front ui
      pp: false,
      off: false,
      terms_idx: -1,
      timeout_id: null,
    };
  },
  methods: {
    // front ui
    pp_on(name) {
      console.log('?');
      this.plan_name = name;
      this.terms_idx = 1;
      this.desc = `<p><span style="">주식회사 골프앤요트(www.golf-yacht.com)(이하 회사라 한다)는 「개인정보 보호법」 제30조에 따라 정부주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립&middot;공개합니다.</span></p>
<br>

<br>
<p><strong><span style="">제1조 (개인정보의 처리 목적)</span></strong></p>
<p><span style="">회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</span></p>

<li>1. 홈페이지 회원가입 및 관리</span></li>
<p><span style="">회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별&middot;인증, 회원자격 유지&middot;관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의 여부 확인, 각종 고지&middot;통지, 고충처리 목적으로 개인정보를 처리합니다.</span></p>


<li>2. 민원사무 처리</span></li>

<p><span style="">민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락&middot;통지, 처리결과 통보 목적으로 개인정보를 처리합니다.</span></p>

<li>3. 재화 또는 서비스 제공</li>

<p><span style="">서비스 제공, 콘텐츠 제공, 본인인증, 연령인증 등을 목적으로 개인정보를 처리합니다.</span></p>

<li>4. 마케팅 및 광고에의 활용</li>

<p><span style="">신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.</span></p>
<br>
<p><strong><span style="">제2조(개인정보의 처리 및 보유 기간)</span></strong></p>
<li>1. <span style="">앱 회원가입 및 관리</span></li>

<p><span style="">앱 회원가입 및 관리와 관련한 개인정보는 수집&middot;이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유&middot;이용됩니다.</span></p>
<p><span style="">보유근거 : 관련 법령에 근거</span></p>
<p><span style="">관련법령: 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년</span></p>
<p><span style="">소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</span></p>
<p><span style="">대금결제 및 재화 등의 공급에 관한 기록 : 5년</span></p>
<p><span style="">계약 또는 청약철회 등에 관한 기록 : 5년</span></p>
<p><span style="">표시/광고에 관한 기록 : 6개월</span></p>
<p><span style="">예외사유 : 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만, 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.</span></p>

<li>2. <span style="">민원사무 처리</span></li>

<p><span style="">민원사무 처리와 관련한 개인정보는 수집&middot;이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유&middot;이용됩니다.</span></p>
<p><span style="">보유근거 : 관련 법령에 근거 (전항과 동일)</span></p>

<li>3. <span style="">마케팅 및 광고에의 활용</span></li>

<p><span style="">마케팅 및 광고에의 활용과 관련한 개인정보는 수집&middot;이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유&middot;이용됩니다.</span></p>
<p><span style="">보유근거 : 관련 법령에 근거 (전항과 동일)</span></p>
<br>
<p><strong><span style="">제3조 (개인정보의 제3자 제공)</span></strong></p>
<li>1. 회사는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하여, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」, 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</span></li>
<li>2. 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</span></li>
<table>
<thead>
    <tr>
      <th style="border: 1px solid #666; padding: 5px;width : 25%">제공받은 업체명</th>
      <th style="border: 1px solid #666; padding: 5px;width : 25%">개인정보 제공목적</th>
      <th style="border: 1px solid #666; padding: 5px;width : 25%">제공하는 개인정보 항목</th>
	  <th style="border: 1px solid #666; padding: 5px;width : 25%">개인정보 보유 및 이용기간</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="border: 1px solid #666; padding: 5px;">${this.plan_name} 골프장</td>
      <td style="border: 1px solid #666; padding: 5px;">골프예약 서비스 이용 계약 이행(서비스 제공확인, 이용자 정보 확인)</td>
      <td style="border: 1px solid #666; padding: 5px;">예약자명, 휴대전화번호, 동반자명, 동반자 휴대전화번호, 예약정보, 결제정보</td>
	  <td style="border: 1px solid #666; padding: 5px;">서비스 이용기간(가입한 서비스 이용 종료 시, 해당 서비스 항목만 제외)</td>
    </tr>
	</tbody>
</table>


<p><span style="">제공받는 자의 개인정보 이용목적 : 이메일, 휴대폰번호, 로그인 아이디, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키 제공받는 자의 보유&middot;이용기간 : 1년</span></p>
<br>

<p><strong><span style="">제4조 (정보주체와 법정대리인의 권리&middot;의무 및 그 행사방법)</span></strong></p>

<li>1. 정보주체는 회사에 대해 언제든지 개인정보 열람&middot;정정&middot;삭제&middot;처리정지 요구 등의 권리를 행사할 수 있습니다.</span></li>
<li>2. 제1항에 따른권리 행사는 주식회사 골프앤요트에 대해 「개인정보 보호법」시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 주식회사 골프앤요트는 이에 대해 지체 없이 조치하겠습니다.</span></li>
<li>3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리 방법에 관한 고시(제2020-7호) 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</span></li>
<li>4. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」, 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.</span></li>
<li>5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</span></li>
<li>6. 회사는 정보주체 관리에 따른 열람의 요구, 정정&middot;삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</span></li>
<br>
<p><strong><span style="">제5조 (처리하는 개인정보의 항목 작성)</span></strong></p>

<li>1. 회사는 다음의 개인정보 항목을 처리하고 있습니다.</span>

<li>A. 홈페이지 회원가입 및 관리<br />필수항목 : 이메일, 휴대전화번호, 비밀번호, 로그인 아이디, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키</span></li>
</li>
<br>
<p><strong><span style="">제6조 (개인정보의 파기)</span></strong></p>

<li>회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.</span></li>
<li>정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속보전하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</span></li>
<li>개인정보 파기의 절차 및 방법은 다음과 같습니다.</span>

<li>파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</span></li>
<li>파기방법 : 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</span></li>

</li>

<br>
<p><strong><span style="">제7조 (개인정보의 안전성 확보 조치)</span></strong></p>
<p><span style="">회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</span></p>

<li>개인정보 취급 직원의 최소화 및 교육<br />개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.</span></li>
<li>해킹 등에 대비한 기술적 대책<br />회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신&middot;점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</span></li>
<li>개인정보의 암호화<br />이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</span></li>
<li>개인정보에 대한 접근 제한<br />개인정보를 처리하는데이터베이스 시스템에 대한 접근권한의부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</span></li>
<li>문서보안을 위한 잠금 장치 사용<br />개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다</span></li>
<li>비인가 자에 대한 출입 통제<br />개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대한 출입통제 절차를 수립, 운영하고 있습니다.</span></li>

<br>
<p><strong><span style="">제8조 (개인정보 자동 수집 장치의 설치&middot;운영 및 거부에 관한 사항)</span></strong></p>

<li>회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 쿠키를 사용합니다.</span></li>
<li>쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에 저장되기도 합니다.<br /><br />쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안 접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br />쿠키의 설치&middot;운영 및 거부 : 웹 브라우저 상단의 도구&gt;인터넷옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</span></li>

<br>
<p><strong><span style="">제9조 (개인정보 보호책임자)</span></strong></p>

<li>회사는 개인정보 처리에 관하 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br /><br />개인정보 보호책임자<br />성명 : 김명일<br />연락처 : 1566-7125 <br />이메일 : golf-yacht@golf-yacht.com</span><br/></li>
<li>정보주체께서는 회사의 서비스 또는 사업을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</span></li>

<br>
<p><strong><span style="">제10조 (개인정보 열람청구)</span></strong></p>
<p><span style="">정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</span></p>

<br>
<p><strong><span style="">제11조 (권익침해 구제방법)</span></strong></p>

<li>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의신고, 상담에 대하여는 아래의기관에 문의하시기 바랍니다.<br /><br />개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br />개인정보침해신고센터 : (국번없이) 118 (priacy.kisa.or.kr)<br />대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br />검찰청 : (국번없이) 182 (cyberbureau.police.go.kr)</span></li>
<li>「개인정보 보호법」제35조, 제36조, 제37조의규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는바에 따라 행정심판을 청구할 수 있습니다.<br /><br />행정심판에 대한 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.</span></li>

<br>
<p>이 개인정보처리방침은 2023년 09월 01일부터 적용됩니다</p>
`;

      this.pp = true;
    },
    pp_off() {
      this.off = true;

      if (this.timeout_id) {
        clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
        this.pp = false;
        this.off = false;
        if (this.$refs.basic_pp_body) {
          this.$refs.basic_pp_body.scrollTop = 0;
        }
        this.timeout_id = null;
      }, 200);
    },
  },
};
</script>

<style scoped></style>
