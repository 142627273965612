import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar';

import common_js from "./assets/js/common.js";
import alert_js from "./assets/js/alert.js";
import axios from "./axios.js"
import VueCookies from 'vue-cookies'

Vue.use(VueCookies, { expires: '1Y'})

import VueQrcodeReader from "vue-qrcode-reader";
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)


Vue.use(VueQrcodeReader);
Vue.config.productionTip = false;
Vue.prototype.$http = axios
Vue.use(common_js);
Vue.use(alert_js);

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Vue.prototype.$EventBus = new Vue();


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
