<template>
    <div :class="$store.state.theme">
        <div id="app" class="bg-body">
            <router-view/>
            <Nav />
        </div>
    </div>
</template>
<script>
    import Nav from '@/components/common/Nav.vue'
    export default {
      components:{
          Nav
      },
    }
</script>
<style lang="scss">
    @import 'assets/css/all.css'; //폰트어썸
    @import '~bootstrap/dist/css/bootstrap.min.css'; //부트스트랩
    @import '~bootstrap-vue/dist/bootstrap-vue.css'; //부트스트랩
    @import 'assets/css/custom-bootstrap.scss';
    @import 'assets/css/common.scss';

    // font 프리텐다드, HS (KR) Poppins (EN)
    @import url('assets/font/Pretendard-1.3.6/web/variable/pretendardvariable-dynamic-subset.css');
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

    body{
        background-color: #eee;
    }
    #app {
        position: relative;
        z-index: 1;
        background-color: #fff;
        // overflow: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        max-width: 768px;
        margin: 0 auto;
        min-height: 100vh;
        padding-bottom: 70px;
        font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic Neo", 'Poppins', "Pretendard Variable", Pretendard, "Segoe UI", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        word-break: break-all;
        &.hide_nav{
            padding-bottom: 0;
        }
    }
    .bg-gradient-primary{
        background: #11998e;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #38ef7d, #11998e);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    #search_box{
        top: 42px;
    }

    .search_list{
        .badges>ul{
            -ms-overflow-style: none;
        }
        .badges>ul::-webkit-scrollbar{
            display: none;
        }
    }
    
    .empty_data{
        padding: 50% 0 50% 0;
        text-align: center;
        opacity: 0.5;
    }

    .category_item{
        .ratio::after{
            content: "";
            background-color: #000;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.05;
        }
    }
    .search_list li{
        .img_box::after{
            content: "";
            background-color: #000;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.05;
        }
    }

</style>
