<template>
    <canvas id="gradient" style="background-color: rgb(205, 222, 161);"></canvas>
</template>

<script>

// import { GlowParticle } from '@/assets/js/glowparticles.js';

export default {
    data(){
        return{
            COLORS: [
                {r: 56, g: 239, b:125},
                {r: 21, g: 87, b:153}, //짙은
                {r: 17, g: 153, b:142}
            ],
            canvas: null,
            ctx:null,

            pixelRatio:0,
            
            totalParticles:0,
            particles: [],
            maxRadius: 0,
            minRadius: 0, 
        }
    },
    mounted(){
        this.constructor();
    },
    methods:{
        
        constructor(){
            this.canvas = document.getElementById('gradient');
            this.ctx = this.canvas.getContext("2d");
            this.pixelRatio = (window.devicePixelRatio > 1) ? 2:1;
            
            this.totalParticles = 10;
            this.particles = [];
            this.maxRadius = 900;
            this.minRadius = 400; 

            // window.addEventListener('resize', this.resize.bind(this), false);
            // this.resize();

            // window.requestAnimationFrame(this.animate.bind(this));
        },
        // resize(){
        //     this.stageWidth = this.canvas.clientWidth;
        //     this.stageHeight = this.canvas.clientHeight;
        //     this.canvas.width = this.stageWidth * this.pixelRatio;
        //     this.canvas.height = this.stageHeight * this.pixelRatio;

        //     this.ctx.scale(this.pixelRatio, this.pixelRatio);
        //     this.ctx.globalCompositeOperation = 'saturation';
        //     this.createParticles();
        // },
        // createParticles(){
        //     let curColor = 0;
        //     this.particles = [];

        //     for ( let i = 0; i < this.totalParticles; i++){
        //         const item = new GlowParticle(
        //             Math.random() * this.stageWidth,
        //             Math.random() * this.stageHeight,
        //             Math.random() *
        //             (this.maxRadius - this.minRadius) + this.minRadius,
        //             this.COLORS[curColor]

        //         );

        //         if(++curColor >=this.COLORS.length){
        //             curColor = 0;
        //         }
        //         this.particles[i] = item;
        //     }
        // },

        // animate(){
        //     window.requestAnimationFrame(this.animate.bind(this));
        //     this.ctx.clearRect(0,0,this.stageWidth, this.stageHeight);
        //     for(let i =0; i< this.totalParticles; i++){
        //         const item= this.particles[i];
        //         item.animate(this.ctx, this.stageWidth, this.stageHeight);
        //     }
        // }
    }
}
</script>

<style>
#gradient{
  position: absolute;
  top: -130px;
  left: 50%;
  transform: translateX(-50%);
  height: 370px;
  /*width: 160%;*/
  width: 100%;
  z-index: -1;
  /*border-radius: 50%;*/
  border-radius: 0 0 20%;
  background: #11998e;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, rgb(56, 239, 125), rgb(17, 153, 142));  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: rgb(21, 87, 153);
}
</style>