
export default{
    install(Vue){
        Vue.prototype.$copy = function(txt) {
            const textarea = document.createElement('textarea');
            textarea.value = txt;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);

            // Vue.prototype.$alert('복사되었습니다.')
        };

        Vue.prototype.$autoTxtColor = function(hexColor){
            // 배경색 명도에 맞춰 글자색 반환(흰/검)
              const c = hexColor.substring(1) // 색상 앞의 # 제거
              const rgb = parseInt(c, 16)   // rrggbb를 10진수로 변환
              const r = (rgb >> 16) & 0xff 
              const g = (rgb >>  8) & 0xff
              const b = (rgb >>  0) & 0xff
              const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
  
              // 절반 기준은 127.5
              return luma < 190 ? "#ffffff" : "#555555" // 글자색 반환
        }
    }
}
